import React, { useState, useEffect, useRef } from "react";
import axios from "utils/axiosConfig";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Stack, Select, MenuItem, IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import TablePagination from "features/candidate/candidate-listing/TablePagination";
import { useSelector, useDispatch } from "react-redux";
import { candidatesList } from "reducers/candidate/candidatesSlice";
import { clearSearchFilters } from "reducers/candidate/searchFiltersDataSlice";
import { urlService } from "infra/envs";
import transformResData from "features/candidate/utils/transformResData";
import tableColumnHeaders from "features/candidate/candidate-listing/tableColumnHeaders";
import useNotifications from "hooks/useNotifications";
import { format } from "date-fns";
import CandidateDrawer from "../candidate-view/CandidateDrawer";
import SocialMediaURL from "services/SocialMediaURL.service";

const RESULT_LENGTH_OPTIONS = [10, 25, 50, 75, 100];
const UNCLICKABLE_CELLS = [
    "view",
    "cv",
    "collegelink_profile_id",
    "linkedin_profile",
];

export const DataTable = ({ setFilterVisibility }) => {
    const dispatch = useDispatch();
    const filtersData = useSelector((state) => state.searchFiltersData);
    const [resultLength, setResultLength] = useState(RESULT_LENGTH_OPTIONS[1]);
    const candidatesApiPrefix = urlService.CANDIDATES_API_PATH;
    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState({});
    const [candidates, setCandidates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [candidatesTableRows, setCandidatesTableRows] = useState([]);
    const [mountCandidateIdToDrawer, setMountCandidateIdToDrawer] =
        useState(null);

    const { renderErrorNotification } = useNotifications();

    // use date-fns package to construct today's date in the required format
    const todaysFormattedDate = format(new Date(), "yyyy-MM-dd");

    const fetchCandidates = async (url, filtersData) => {
        // assign default values to start date & end date when they are left blank
        let rp_status_start_date =
            filtersData?.rp_status_start_date ?? "2000-01-01";
        let rp_status_end_date =
            filtersData?.rp_status_end_date ?? todaysFormattedDate;

        // if both start date and end date are undefined, set them back to undefined. This is the state of the initial search
        if (
            filtersData?.rp_status_start_date === undefined &&
            filtersData?.rp_status_end_date === undefined
        ) {
            rp_status_start_date = filtersData?.rp_status_start_date;
            rp_status_end_date = filtersData?.rp_status_end_date;
        }

        // create new object with the ids of each multi field
        const searchFiltersDataIds = {
            ...filtersData,
            skills: filtersData.skills?.map((skill) => skill.id).join(),
            jobFunctions: filtersData.jobFunctions?.map((item) => item.id),
            occupations: filtersData.occupations?.map((item) => item.id),
            cities: filtersData.city?.map((city) => city.id).join(),
            workingModels: filtersData.workingModels?.map((model) => model.id),
            jobTypes: filtersData.jobTypes?.map((item) => item.id),
            seniority: filtersData.seniority?.map((item) => item.id).join(),
            experience_years: filtersData?.experience_years,
            first_name: filtersData?.first_name,
            last_name: filtersData?.last_name,
            email: filtersData?.email,
            phone_number: filtersData?.phone_number,
            salary: filtersData?.salary,
            linkedin_profile: SocialMediaURL.extractLinkedinId(
                filtersData?.linkedin_profile
            ),
            collegelink_id: SocialMediaURL.extractLinqId(
                filtersData?.collegelink_id
            ),
            recruiters: filtersData.recruiters?.map(
                (recruiter) => recruiter.id
            ),
            jobs: filtersData.jobs?.map((job) => job.id),
            companies: filtersData.companies?.map((company) => company.id),
            rp_states: filtersData.rp_states,
            rp_statuses: filtersData.rp_statuses,
            rp_status_start_date: rp_status_start_date,
            rp_status_end_date: rp_status_end_date,
        };

        try {
            setIsLoading(true);
            const response = await axios.get(url, {
                params: {
                    ...searchFiltersDataIds,
                    page_size: resultLength,
                    page: page,
                },
            });

            const resData = response?.data;
            const fetchedCandidates = resData?.data;
            setPaginationData(resData?.meta);

            setCandidates(fetchedCandidates);

            setIsLoading(false);
        } catch (error) {
            console.error("OOPS!" + error.message);
            renderErrorNotification(`${error?.response?.data?.message}`);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        setPage(1);
    }, [filtersData, resultLength]);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const drawerHandler = {
        isDrawerOpen,
        setIsDrawerOpen,
        mountCandidateIdToDrawer: mountCandidateIdToDrawer,
    };

    const handleCellClick = (params) => {
        if (UNCLICKABLE_CELLS.includes(params.field)) return;
        setIsDrawerOpen(true);
        setMountCandidateIdToDrawer(params?.id);
    };

    const isMounted = useRef(false);

    // clear search filters when DataTable is unmounted
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            dispatch(clearSearchFilters());
        };
    }, [dispatch]);

    //initial render of candidates
    useEffect(() => {
        dispatch(candidatesList(candidates));

        setCandidatesTableRows(transformResData(candidates));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersData, candidates]);

    useEffect(() => {
        fetchCandidates(candidatesApiPrefix, filtersData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultLength, page, filtersData]);

    const handleResultLengthChange = (event) => {
        setResultLength(event.target.value);
    };

    //cleanup
    useEffect(() => {
        return setIsLoading(false);
    }, []);

    return (
        <>
            <CandidateDrawer drawerHandler={drawerHandler} />
            <Stack px={2}>
                <Stack alignItems="flex-start">
                    <IconButton
                        aria-label="Collapse or expand list"
                        onClick={() => {
                            setFilterVisibility((visibility) => !visibility);
                        }}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Stack>
                <Stack
                    pb={3}
                    direction="row"
                    alignItems="center"
                    gap={2}
                    justifyContent={"flex-end"}
                >
                    <Typography>Rows per page</Typography>
                    <Select
                        name="resultLength"
                        value={resultLength}
                        onChange={handleResultLengthChange}
                        label="results per page"
                        variant="standard"
                    >
                        {RESULT_LENGTH_OPTIONS.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
                <DataGrid
                    onCellClick={handleCellClick}
                    component="table"
                    rowSpacingType="margin"
                    autoHeight
                    rows={candidatesTableRows}
                    columns={tableColumnHeaders}
                    pageSize={resultLength}
                    disableColumnMenu
                    hideFooter
                    loading={isLoading}
                    sx={{
                        border: 0,
                        "& .MuiDataGrid-cell:focus,& .MuiDataGrid-columnHeader:focus":
                        {
                            outline: "none",
                            borderColor: "rgba(0,0,0,0)",
                        },
                        "& .MuiDataGrid-row:focus-within": {
                            boxShadow: "none",
                        },
                    }}
                />
                <TablePagination
                    isLoading={isLoading}
                    setPage={setPage}
                    page={page}
                    paginationData={paginationData}
                />
            </Stack>
        </>
    );
};

export default DataTable;
