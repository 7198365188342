class EnvService {
    /**
     * Get backend app URL witout trailing slash
     * ex. http://localhost:8000
     * @returns {string}
     */
    getBackEndBaseUrl() {
        const { REACT_APP_BASE_BACKEND_URL } = process.env;
        return REACT_APP_BASE_BACKEND_URL;
    }
    /**
     * Get collegelink app URL witout trailing slash
     * ex. http://www.collegelink.gr
     * @returns {string}
     */
    getCollegeLinkBaseUrl() {
        const { REACT_APP_COLLEGELINK_BASE } = process.env;
        return REACT_APP_COLLEGELINK_BASE;
    }

    /**
     * Get linq app URL witout trailing slash
     * ex. http://linq.co
     * @returns {string}
     */
    getEmployerLinqBaseUrl() {
        const { REACT_APP_EMPLOYER_BASE } = process.env;
        return REACT_APP_EMPLOYER_BASE;
    }

    /**
     * Get linq app URL witout trailing slash
     * ex. http://linq.co
     * @returns {string}
     */
    getCandidateLinqBaseUrl() {
        const { REACT_APP_LINQ_BASE } = process.env;
        return REACT_APP_LINQ_BASE;
    }
}

const envServiceInstance = new EnvService();

export default envServiceInstance;
